<div *ngIf="fileType === 'pdf'" class="pdf-container">
  <!-- in case of an error -->
  <app-status [status]="404" [url]="src.fileValue.fileUrl" [representation]="'document'" *ngIf="failedToLoad">
  </app-status>

  <pdf-viewer
    class="pdf-viewer"
    [src]="{ url: src.fileValue.fileUrl,  withCredentials: true }"
    [original-size]="false"
    [autoresize]="true"
    [show-all]="true"
    [show-borders]="true"
    [zoom]="zoomFactor"
    [zoom-scale]="'page-width'">
  </pdf-viewer>

  <div>
    <div class="action horizontal bottom">
      <!-- caption -->
      <span>
        <button mat-icon-button [matMenuTriggerFor]="more">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #more="matMenu" class="representation-menu">
          <button class="menu-content" mat-menu-item (click)="download(src.fileValue)">Download file</button>
          <button [disabled]="!usercanEdit" class="menu-content" mat-menu-item (click)="openReplaceFileDialog()">
            Replace file
          </button>
        </mat-menu>
      </span>

      <!-- input field for searching through document -->
      <input
        matInput
        #queryInp
        type="text"
        id="searchbox"
        name="searchbox"
        class="pdf-searchbox fill-remaining-space"
        placeholder="Search in pdf..."
        [value]="pdfQuery"
        [disabled]="failedToLoad"
        (input)="searchQueryChanged($event.target.value)"
        (keyup.enter)="searchQueryChanged(queryInp.value)" />

      <!-- image action tools e.g. zoom, rotate and flip -->
      <span>
        <!-- zoom buttons -->
        <button
          mat-icon-button
          id="DSP_PDF_ZOOM_OUT"
          matTooltip="Zoom out"
          (click)="zoom('out')"
          [disabled]="failedToLoad">
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
        <button
          mat-icon-button
          id="DSP_PDF_HOME"
          matTooltip="Reset zoom"
          (click)="zoomFactor = 1.0"
          [disabled]="failedToLoad">
          <mat-icon>adjust</mat-icon>
        </button>
        <button
          mat-icon-button
          id="DSP_PDF_ZOOM_IN"
          matTooltip="Zoom in"
          (click)="zoom('in')"
          [disabled]="failedToLoad">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </span>

      <!-- empty placeholders to center the zoom buttons -->
      <span class="fill-remaining-space"></span>

      <!-- full screen button -->
      <span>
        <button
          mat-icon-button
          id="DSP_PDF_FULL_SCREEN"
          matTooltip="Fullscreen"
          (click)="openFullscreen()"
          [disabled]="failedToLoad">
          <mat-icon>fullscreen</mat-icon>
        </button>
      </span>
    </div>
  </div>
</div>
<!-- default document viewer -->
<div *ngIf="fileType !== 'pdf'">
  <div class="file-representation">
    <div class="container">
      <div class="contents">
        <div class="icon">
          <mat-icon> text_snippet </mat-icon>
        </div>
        <div class="file">
          <p>{{ originalFilename }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="toolbar">
    <!-- toolbar -->
    <div class="action horizontal bottom">
      <!-- three dot menu to download and replace file -->
      <button mat-icon-button [matMenuTriggerFor]="more">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #more="matMenu" class="mat-menu-custom-black">
        <button class="menu-content" mat-menu-item (click)="download(src.fileValue)" [disabled]="failedToLoad">
          Download file
        </button>
        <button [disabled]="!usercanEdit" class="menu-content" mat-menu-item (click)="openReplaceFileDialog()">
          Replace file
        </button>
      </mat-menu>
    </div>
  </div>
</div>
