<mat-card data-cy="class-card" appearance="outlined" class="resource-class" cdkDrag cdkDragBoundary=".drag-drop-stop">
  <!-- class card header -->
  <mat-card-header class="resource-class-header" cdkDragHandle>
    <mat-card-title [matTooltip]="resourceClass.comment" matTooltipPosition="above">
      {{ classLabel | appTruncate: 24 }}
    </mat-card-title>
    <mat-card-subtitle>
      <span [matTooltip]="'id: ' + resourceClass.id" matTooltipPosition="above" matTooltipClass="wide-tooltip">
        {{ resourceClass.id | split: '#':1 }}
      </span>
      <span>&nbsp;&middot;&nbsp;</span>
      <span matTooltip="subclass of" matTooltipPosition="above"> {{ subClassOfLabel }} </span>
    </mat-card-subtitle>
    <span class="resource-class-header-action">
      <span
        class="resource-class-more"
        *ngIf="projectStatus"
        [matTooltip]="(ontology.lastModificationDate ? 'more' : 'This data model can\'t be edited because of missing lastModificationDate!')">
        <button
          mat-icon-button
          [matMenuTriggerFor]="resClassMenu"
          class="res-class-menu"
          [disabled]="!ontology.lastModificationDate"
          data-cy="more-button"
          (click)="canBeDeleted()">
          <mat-icon>more_horiz</mat-icon>
        </button>
      </span>
      <span class="resource-class-size" [matTooltip]="(expanded ? 'Collapse class' : 'Expand class')">
        <button mat-icon-button (click)="expanded = !expanded">
          <mat-icon>{{ expanded ? "expand_less" : "expand_more" }}</mat-icon>
        </button>
      </span>
    </span>
  </mat-card-header>
  <!-- resource class card content with list of properties -->

  <mat-card-content
    *ngIf="expanded && currentOntologyPropertiesToDisplay$ | async as currentOntologyPropertiesToDisplay"
    class="resource-class-content">
    <mat-list
      cdkDropList
      class="resource-class-properties"
      (cdkDropListDropped)="drop($event, currentOntologyPropertiesToDisplay)"
      *ngIf="currentOntologyPropertiesToDisplay?.length; else noProperties">
      <app-resource-class-info-element
        *ngFor="let prop of currentOntologyPropertiesToDisplay; let i = index; trackBy: trackByPropToDisplayFn"
        [prop]="prop"
        [props]="currentOntologyPropertiesToDisplay"
        [resourceClass]="resourceClass"
        [userCanEdit]="userCanEdit"
        [i]="i"
        (removePropertyFromClass)="removeProperty($event, currentOntologyPropertiesToDisplay)">
        >
      </app-resource-class-info-element>
    </mat-list>

    <app-add-property-menu
      [resourceClass]="resourceClass"
      [currentOntologyPropertiesToDisplay]="currentOntologyPropertiesToDisplay$ | async"
      [userCanEdit]="userCanEdit"
      [projectStatus]="projectStatus"
      (updatePropertyAssignment)="updatePropertyAssignment.emit($event)"></app-add-property-menu>
  </mat-card-content>
</mat-card>

<ng-template #noProperties>
  <p>There are no properties assigned to this class yet.</p>
</ng-template>

<mat-menu #resClassMenu="matMenu" xPosition="before">
  <span *ngIf="userCanEdit">
    <button
      mat-menu-item
      class="res-class-edit"
      (click)="editResourceClass.emit({ iri: resourceClass.id, label: resourceClass.label })">
      Edit resource class info
    </button>
  </span>
  <span
    [matTooltip]="classCanBeDeleted ? 'This class doesn\'t have any instances yet' : null"
    matTooltipPosition="before">
    <button mat-menu-item (click)="openResourceInstances(resourceClass.id)" [disabled]="classCanBeDeleted">
      Open resource instances
    </button>
  </span>
  <span
    *ngIf="userCanEdit"
    [matTooltip]="classCanBeDeleted ? null : 'This class can\'t be deleted because it is in use'"
    matTooltipPosition="before">
    <button
      mat-menu-item
      [disabled]="!classCanBeDeleted || !userCanEdit"
      class="res-class-delete"
      data-cy="delete-button"
      (click)="deleteResourceClass.emit({ iri: resourceClass.id, label: resourceClass.label })">
      Delete resource class
    </button>
  </span>
</mat-menu>
